input[type="date"]::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator{
  display: none;
}

input[type="date"]::-webkit-input-placeholder{ 
  visibility: hidden !important;
}