.app {
  text-align: center;
  margin: 2em;
  padding: 20px 40px;
  /* border: 1px solid #e7ecf1; */
}

.main {
  margin: 20px;
}

.menu-toggle {
  position: absolute;
  top: 20px;
  left: 10px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: all .3s;
}

.menu-toggle:hover,
.menu-toggle:focus {
  color: #1890ff;
}

.ant-modal-body canvas {
  cursor: none;
}
