.monitor-beauty-panel {
  .monitor-beauty-header {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 20px;

    .monitor-beauty-header-content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px 10px;

      .ant-card {
        border-radius: 7px;
        border-color: #c0c0c0;
        background: #fff9f7;
        width: 180px;

        .ant-card-body {
          padding: 10px 5px;
        }

        .ant-statistic {
          padding: 0 10px;
          border-right: 1px solid #f0f0f0;

          .ant-statistic-title {
            font-weight: 600;
            font-size: 15px;
            color: black;
          }

          .ant-statistic-content {
            font-size: 24px;
          }
        }

        .ant-statistic:last-child {
          border-right: 0;
        }
      }
    }
  }

  .monitor-beauty-anchorList-warp {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
    padding: 20px;

    .ant-ribbon-wrapper {
      .ant-ribbon {
        padding: 0 20px;
        height: 25px;
        line-height: 25px;

        .ant-ribbon-text {
          font-size: 16px;
          font-weight: 600;
        }

      }
    }

    .ant-card {

      border-radius: 15px;
      border-color: #e0e0e0;
      background: #fff9f7;


      .ant-card-actions, .ant-card-body {
        border-radius: 15px;

      }

      .ant-card-actions li {
        margin: 0;
      }


      &.normal {
        background: #eaffe0;
      }

      &.private {
        background: #ffffd8;
      }

      .ant-card-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px 0 10px 0;


        .ant-avatar {
          padding: 5px;
          border: 1px solid #e0e0e0;
          background: #e0e0e0;

          img {
            border-radius: 50%;
          }
        }

        .name {
          margin-top: 5px;
          font-size: 20px;
          font-weight: 600;
          max-width: 200px;
          -webkit-text-emphasis: inherit;
          text-emphasis: inherit;
          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }


        .stream-name {
          margin-top: 5px;
          display: flex;
          justify-content: center;
          width: 100%;
          align-items: center;
        }

        .view-btn {
          margin-top: 10px;
        }
      }
    }

  }

  .monitor-panel-warp {
    display: flex;
    gap: 10px;

    .monitor-player-warp {
      max-height: 550px;
      min-height: 300px;
      width: 50%;

      video {
        object-fit: contain;
      }
    }

    .monitor-detail-warp {
      width: 50%;

      .monitor-detail-content-warp {
        margin-bottom: 10px;
        .ant-card{
          border-color: #e0e0e0;
          .ant-card-body{
            padding:15px 15px;
          }
          .ant-card-actions li {
            margin: 0;
            background: #b2d9ff;
            color:black;
          }
        }

        .title{
          font-size: 20px;
          font-weight: 600;
        }
        .description{
          font-size: 14px;
          >div{
            display: flex;
            align-items: center;
          }
        }
      }

      .monitor-chat-warp {
        height: 400px;

        .message-body {
          height: 350px;
          background-color: rgba(5, 5, 5, 0.8);
          overflow-y: auto;

          .message-item {
            display: flex;
            padding: 5px;

            &.center {
              justify-content: center;
            }

            .content {
              padding: 4px 8px;
              background: rgba(0, 0, 0, .5);
              border-radius: 2px;
              font-size: 15px;
              line-height: 1.2;
              word-break: break-all;

              .name{
                color: #ebf3f3;
                font-weight: bold;
                margin-right: 10px;
              }
              .msg{
                color: #e4e4e4;
                font-weight: bold;
                img{
                  width: 25px;
                  vertical-align: top;
                }
              }

              &.monitor {
                background: rgba(98, 0, 255, 0.918);
              }
              &.error{
                background: rgb(180, 4, 4);
                .name{
                  color: #fff;
                }
              }


            }
          }

        }

        .input {
          height: 50px;
        }
      }
    }
  }
}

