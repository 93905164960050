.ant-ribbon-wrapper {
  position: relative;
  height: 100%;
  .ant-ribbon {
    box-sizing: border-box;
    margin: 0;
    font-size: 12px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: -13px;
    height: 15px;
    padding: 0 8px;
    color: #fff;
    line-height: 15px;
    white-space: nowrap;
    background-color: #52c41a;
    border-radius: 2px;

    .ant-ribbon-corner {
      position: absolute;
      top: 100%;
      width: 8px;
      height: 8px;
      color: currentColor;
      border: 4px solid;
      transform: scaleY(.75);
      transform-origin: top;
      &:after{
        position: absolute;
        top: -4px;
        left: -4px;
        width: inherit;
        height: inherit;
        color: #00000040;
        border: inherit;
        content: "";
      }
    }

    &.ant-ribbon-placement-end {
      right: -8px;
      border-bottom-right-radius: 0;

      .ant-ribbon-corner {
        right: 0;
        border-color: currentColor transparent transparent currentColor;
      }
    }

    &.ant-ribbon-placement-start {
      left: -18px;
      border-bottom-right-radius: 0;

      .ant-ribbon-corner {
        left: 0;
        border-color: currentColor currentColor transparent transparent;
      }
    }

    .ant-ribbon-text {
      color: #fff;
    }

    &.ant-ribbon-color-red {
      color: #f5222d;
      background: #f5222d;
    }

    &.ant-ribbon-color-red {
      color: #f5222d;
      background: #f5222d;
    }

    &.ant-ribbon-color-green {
      color: #52c41a;
      background: #52c41a;
    }

    &.ant-ribbon-color-volcano {
      color: #fa541c;
      background: #fa541c;
    }
  }
}
