.icon-style {
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
}

.icon-style p {
  text-align: center;
  margin-bottom: 0;
}

.icon-style img {
  width: 100%;
}

.gamelist {
  &.true {
    background-color:lightgrey;
  }
}