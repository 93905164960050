.live-content {
  display: flex;
  width: 100%;
  height: calc(100vh - 6rem);

  .left-panel {
    width: 25%;
  }

  .right-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 75%;
  }

  .state-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .online-count {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      border: 1px solid black;
      border-radius: 1rem;
      min-width: 4rem;
      height: 2rem;
      padding: 12px;
    }
  }

  .manager-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .5rem;
  }
}