.socket-control {
  height: 78vh;
}

.las {
  display: flex;
  flex-direction: column;
}

.las .message-block {
  padding: 8px;
  height: 100%;
  overflow-y: auto;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.9);
}

.las .message-block .row {
  display: flex;
}
.las .message-block .row.center {
  justify-content: center;
}

.las .message-block .rank {
  font-size: 12px;
  color: #e4e4e4;
  background-color: rgb(134, 130, 128);
  text-align: center;
  width: 30px;
  border-radius: 3px;
}

.las .message-block .manager {
  font-size: 12px;
  color: #e4e4e4;
  background-color: #f50;
  text-align: center;
  width: 30px;
  border-radius: 3px;
}

.las .message-block .content {
  position: relative;
  color: #e4e4e4;
  padding: 1px 5px 1px 5px;
  margin-bottom: 12px;
  font-size: 12px;
  word-break: break-all;
  font-weight: bold;
}

.las .message-block .content:before {
  content: " ";
  position: absolute;
  top: 13px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 25px;
}
.las .message-block .content.color-1:before {
  background-color: #30bafa;
}
.las .message-block .content.color-2:before {
  background-color: #e20000;
}
.las .message-block .content.color-3:before {
  background-color: #fac830;
}
.las .message-block .content.color-4:before {
  background-color: #00e2ca;
}
.las .message-block .content.color-5:before {
  background-color: #fa8130;
}
.las .message-block .content.color-6:before {
  background-color: #5943ff;
}
.las .message-block .content.color-7:before {
  background-color: #b555ff;
}
.las .message-block .content.color-8:before {
  background-color: #ec23d7;
}
.las .message-block .content.color-9:before {
  background-color: #7bbb47;
}
.las .message-block .content.color-10:before {
  background-color: #fff000;
}

.las .message-block .content .name {
  font-size: 13px;
  color: #b9b7b7;
  margin-right: 6px;
  font-weight: bold;
}

.las .message-block .content .msg img {
  width: 25px;
  vertical-align: top;
}

.las .message-block .content.success {
  padding: 8px 15px 10px 15px;
  background: rgba(38, 218, 83, 0.774);
}

.las .message-block .content.error {
  padding: 8px 15px 10px 15px;
  background: rgba(255, 56, 14, 0.6);
}

.las .message-block .content.monitor {
  padding: 1px 5px 1px 5px;
  border-radius: 2px;
  animation: montior 3 linear 2s;
}

.las .message-block .content.monitor.name {
  font-size: 13px;
  color: #fcf8f8;
  background-color: #f50707;
  margin-right: 6px;
  font-weight: bold;
}

.las .message-block .content.model .name {
  color: #da6464;
}

@keyframes montior {
  0% {
    background: rgba(254, 254, 41, 0.5);
  }
  50% {
    background: rgb(38, 38, 201, 0.5);
  }
  100% {
    background: red;
  }
}

.las .message-input {
  margin-top: 10px;
}
.las .message-input input {
  padding: 10px;
}
.las .message-input button {
  padding: 5px 10px;
}

.video-js .vjs-icon-refresh {
  background-image: url("../assets/images/refresh.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 55% calc(50% - 0px);
}
